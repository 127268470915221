import axios from 'axios'

export default class LosowanieClass {
  url = 'https://api.veolab.me/api/'
  key = '5AnFElrhUJTUwps0OtpQWjbTDynzeGrQ'

  aktualizuj () {
    return new Promise((resolve, reject) => {
      window.localStorage.clear()
      axios
        .get(this.url + 'losowania/aktualizuj', {
          params: {
            key: this.key
          }
        })
        .then(res => {
          resolve(res)
        })
    })
  }

  getDataGame (game) {
    return new Promise((resolve, reject) => {
      const data = JSON.parse(window.localStorage.getItem('losowanie_get_all_' + game))
      if (data !== null) {
        resolve(data)
      } else {
        axios
          .get(this.url + 'losowanie/get/all/' + game, {
            params: {
              key: this.key
            }
          })
          .then(res => {
            window.localStorage.setItem('losowanie_get_all_' + game, JSON.stringify(res.data))
            resolve(res.data)
          })
      }
    })
  }

  getDataGameLimit (game, limit) {
    return new Promise((resolve, reject) => {
      const data = JSON.parse(window.localStorage.getItem('losowanie_get_all_' + game + '_' + limit))
      if (data !== null) {
        resolve(data)
      } else {
        axios
          .get(this.url + 'losowanie/get/all/' + game + '/' + limit, {
            params: {
              key: this.key
            }
          })
          .then(res => {
            window.localStorage.setItem('losowanie_get_all_' + game + '_' + limit, JSON.stringify(res.data))
            resolve(res.data)
          })
      }
    })
  }

  getDataGameConcatLimit (game, limit) {
    return new Promise((resolve, reject) => {
      const data = JSON.parse(window.localStorage.getItem('losowanie_get_all_concat_' + game + '_' + limit))
      if (data !== null) {
        resolve(data)
      } else {
        axios
          .get(this.url + 'losowanie/get/all_concat/' + game + '/' + limit, {
            params: {
              key: this.key
            }
          })
          .then(res => {
            window.localStorage.setItem('losowanie_get_all_concat_' + game + '_' + limit, JSON.stringify(res.data))
            resolve(res.data)
          })
      }
    })
  }

  saveLosowanie (game, liczby, addon, user) {
    return new Promise((resolve, reject) => {
      window.localStorage.clear()
      axios
        .post(this.url + 'moje/losowanie/save/' + game, {
          _game: game,
          _liczby: liczby,
          _addon: addon,
          _user: user,
          key: this.key
        })
        .then(res => {
          resolve(res.data)
        })
    })
  }

  getMojeLosowanie (game, user) {
    return new Promise((resolve, reject) => {
      axios
        .get(this.url + 'moje/losowanie/get/' + game, {
          params: {
            _user: user,
            key: this.key
          }
        })
        .then(res => {
          window.localStorage.setItem('moje_losowanie_get_' + game, JSON.stringify(res.data))
          resolve(res.data)
        })
    })
  }

  removeMojeLosowanie (game, elementId) {
    return new Promise((resolve, reject) => {
      window.localStorage.clear()
      axios
        .post(this.url + 'moje/losowanie/remove/' + game, {
          id: elementId,
          key: this.key
        })
        .then(res => {
          resolve(res.data)
        })
    })
  }

  pobierz (game) {
    console.log(game)
  }
}
